import updateHead from "../../../utils/helper/updatePageHead";
import { useState, useEffect } from "react";
import { getLicenseKeysFromUserId } from "../../../utils/api/userFetchs";
import { useUser } from "../../../utils/contexts/userContext";
import { AgChartsReact } from 'ag-charts-react';
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { groupByYear, statisticsTheme, buildDataStackChartInOut, buildDataPieChartInOut, buildDataPieChartQueries } from "../../../utils/helper/statisticsHelper";
import { useLogout } from "../../../utils/helper/logoutUser";

export default function AccountStatistics() {
    const title = "DIPS | Vos statistiques";

    const { user } = useUser();
    const [userLicenseKeys, setUserLicenseKeys] = useState([])
    const [selectedLicenseKey, setSelectedLicenseKey] = useState(null);
    const [chartsResolution, setChartsResolution] = useState("year")
    const { logoutUser } = useLogout();

    useEffect(() => {
        if (user._id) {
            getLicenseKeysFromUserId(user, logoutUser).then(license_keys => {
                if (license_keys) {
                    setUserLicenseKeys(license_keys)
                    setSelectedLicenseKey(license_keys[0])
                }
            });
        }
    }, [user])

    useEffect(() => {
        if (selectedLicenseKey) {
            const dataInOutPieChart = buildDataPieChartInOut(selectedLicenseKey.data_in, selectedLicenseKey.data_out)
            setOptionsPieChartDataInOut((previous) => ({ ...previous, "data": dataInOutPieChart }))

            const dataQueriesPieChart = buildDataPieChartQueries(selectedLicenseKey.logs)
            setOptionsPieChartDataQueries((previous) => ({ ...previous, "data": dataQueriesPieChart }))

            const dataInOutStackBar = buildDataStackChartInOut(selectedLicenseKey.data_in, selectedLicenseKey.data_out, groupByYear)
            setOptionsStackChartInOut((previous) => ({ ...previous, "data": dataInOutStackBar }))
        }

    }, [selectedLicenseKey])

    const [optionsStackChartInOut, setOptionsStackChartInOut] = useState({
        theme: statisticsTheme,
        title: {
            text: "Quantité de données transitant via Peasys",
        },
        data: [],
        series: [
            {
                type: 'bar',
                xKey: 'xAxis',
                yKey: 'In',
                yName: 'In',
                stacked: true,
            },
            {
                type: 'bar',
                xKey: 'xAxis',
                yKey: 'Out',
                yName: 'Out',
                stacked: true,
            }
        ],
    });

    const [optionsPieChartDataInOut, setOptionsPieChartDataInOut] = useState({
        theme: statisticsTheme,
        subtitle: {
            text: "Quantité de données transitant via Peasys",
        },
        data: [
            { asset: 'Data In', amount: 0, yield: 1 },
            { asset: 'Data Out', amount: 0, yield: 2 },
        ],
        series: [
            {
                type: 'pie',
                angleKey: 'amount',
                calloutLabelKey: 'asset',
                sectorLabelKey: 'amount',
            },
        ],
    });

    const [optionsPieChartDataQueries, setOptionsPieChartDataQueries] = useState({
        theme: statisticsTheme,
        subtitle: {
            text: "Répartition des requêtes en %",
        },
        data: [
            { asset: 'SELECT', amount: 0, yield: 1 },
            { asset: 'CREATE', amount: 0, yield: 2 },
            { asset: 'UPDATE', amount: 0, yield: 3 },
            { asset: 'DROP', amount: 0, yield: 4 },
            { asset: 'DELETE', amount: 0, yield: 5 },
            { asset: 'ALTER', amount: 0, yield: 6 },
            { asset: 'INSERT', amount: 0, yield: 7 },
        ],
        series: [
            {
                type: 'pie',
                angleKey: 'amount',
                calloutLabelKey: 'asset',
                sectorLabelKey: 'amount',
            },
        ],
    });

    if (!userLicenseKeys || !selectedLicenseKey) {
        return (
            <>
                <section className="section">
                    <div className="section-container">
                        <div className="section-container-layout">
                            <div className="section-row-layout">
                                <header>
                                    <h1 className="section-content-title">Vos statistiques !</h1>
                                    <h2 className="section-content-subtitle">Pour restez au plus proche de vos données</h2>
                                </header>
                                <p>Vous n'avez pas encore de clé license...</p>
                                <Link to='/pricing' style={{ width: 'fit-content' }}>
                                    <button className="dark-bg">Découvrir nos formules</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }

    return (
        <>
            {updateHead({ title })}
            <section className="section">
                <div className="section-container">
                    <div className="section-container-layout">
                        <header>
                            <h1 className="section-content-title">Vos statistiques !</h1>
                            <h2 className="section-content-subtitle">Pour restez au plus proche de vos données</h2>
                        </header>
                        {userLicenseKeys &&
                            (<div className='section-stats-licensekey-selector-container'>
                                <label>Nom de la partition</label>
                                <select
                                    name="license_keys"
                                    onChange={e => setSelectedLicenseKey(userLicenseKeys.find(key => key.key === e.target.value))}>
                                    {userLicenseKeys.map((e, key) => {
                                        return <option key={key} value={e.key}>{e.partition_name}</option>;
                                    })}
                                </select>
                            </div>)
                        }
                    </div>
                </div>
            </section>
            <section className="section">
                <div className="section-container">
                    <div className="section-container-layout" style={{ width: '85%' }}>
                        <h2 className="section-subtitle-special">Statistiques en cumulées</h2>
                        <div className="section-columns-layout" grid-template="1,1">
                            <div className="section-info-container ag-chart-piechart">
                                <AgChartsReact options={optionsPieChartDataInOut} />
                            </div>
                            <div className="section-info-container ag-chart-piechart">
                                <AgChartsReact options={optionsPieChartDataQueries} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section">
                <div className="section-container">
                    <div className="section-container-layout" style={{ width: '85%' }}>
                        <h2 className="section-subtitle-special">Statistiques par
                            <span
                                style={{
                                    backgroundColor: 'lightblue', padding: '5px', margin: '10px',
                                    borderRadius: '10px', border: '4px solid rgb(143, 203, 223, 0.5)'
                                }}>année</span>
                        </h2>
                        <div className="section-info-container">
                            <AgChartsReact options={optionsStackChartInOut} />
                        </div>
                    </div>
                </div>
            </section>
            <Logs licenseKeyLogs={selectedLicenseKey.logs.sort((a, b) => a.execution_date < b.execution_date)} />
        </>
    )
}

const Logs = ({ licenseKeyLogs }) => {

    const [selectedLog, setSelectedLog] = useState(licenseKeyLogs ? licenseKeyLogs[0] : null)

    if (!selectedLog) {
        return (
            <section className="section">
                <div className="section-container">
                    <div className="section-container-layout" style={{ width: '85%' }}>
                        <div className="section-info-container" style={{ width: '90%' }}>
                            <h2>Retrouvez ici vos logs de l'utilisation de Peasys</h2>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
    return (
        <section className="section">
            <div className="section-container">
                <div className="section-container-layout" style={{ width: '85%' }}>
                    <h2 className="section-subtitle-special">Logs d'utilisation de Peasys</h2>
                    <div className="section-info-container" style={{ width: '90%' }}>
                        <div className="section-columns-layout" grid-template="1,1">
                            <ul className="section-account-statistics-logs-list">
                                {licenseKeyLogs.map((log, idx) => (
                                    <li
                                        key={idx}
                                        className={"section-account-statistics-log " + (selectedLog === log ? "focus" : undefined)}
                                        onClick={() => setSelectedLog(log)} >
                                        <span>{log.user}</span>
                                        <span>{log.query}</span>
                                        <div style={{ display: 'flex', flexDirection: 'column', fontSize: '10px', textAlign: 'center' }}>
                                            <span>{dayjs(log.execution_date).format("MM-DD-YYYY")}</span>
                                            <span>{dayjs(log.execution_date).format("hh:mm:ss")}</span>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                            <div>
                                <p>Code : {selectedLog.sql_code}</p>
                                <p>Message : {selectedLog.sql_message}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}