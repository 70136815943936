import { useNavigate } from 'react-router-dom';
import { useUser } from '../contexts/userContext';

export function useLogout() {
  const { logoutLocalUser } = useUser(); // Utiliser le hook ici
  const navigate = useNavigate();

  function logoutUser() {
    logoutLocalUser();
    navigate('/login'); // Rediriger vers la page login
  }

  return { logoutUser }; // Retourner la fonction pour l'utiliser dans d'autres composants
}
