import Loader from "react-js-loader";
import { Link, useParams } from "react-router-dom"
import { useStripe } from "@stripe/react-stripe-js";
import { useState, useEffect, useCallback } from 'react'
import { useUser } from "../../utils/contexts/userContext";
import { deleteLicenseKey, getLicenseKey, updateLicenseKey } from "../../utils/api/licensekeyFetchs";

export default function PayementStatus() {

    const { user } = useUser()
    const { licenseKeyId } = useParams()

    const stripe = useStripe();


    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(true)

    const [link, setLink] = useState("/account/subscriptions");
    const [buttonText, setButtonText] = useState("Voir mes clés");

    const registerKey = useCallback(async () => {
        var key = await getLicenseKey(licenseKeyId)
        key.is_available = true
        const updatedLicenseKey = await updateLicenseKey(user, key)

        setIsLoading(false)
    }, [user, licenseKeyId])

    const handlePayementFailed = useCallback(async () => {
        setIsLoading(false)
        await deleteLicenseKey(licenseKeyId)
        setLink("/pricing")
        setButtonText("Retourner à la page des tarifs")
    }, [licenseKeyId])

    useEffect(() => {
        if (!stripe) {
            return;
        }

        const clientSecret = new URLSearchParams(window.location.search).get("payment_intent_client_secret");
        if (!clientSecret) {
            return;
        }

        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            switch (paymentIntent.status) {
                case "succeeded":
                    registerKey()
                    setMessage("Paiement effectué.");
                    break;
                case "processing":
                    setMessage("Le paiement est en cours de traitement.");
                    window.location.reload()
                    break;
                case "requires_payment_method":
                    setMessage("Votre paiement a échoué.");
                    handlePayementFailed()
                    break;
                default:
                    setMessage("Quelque chose ne vas pas.");
                    break;
            }
        });

    }, [stripe, registerKey, handlePayementFailed]);

    if (isLoading) {
        return (
            <section className="section">
                <div className="section-container">
                    <div className="section-container-layout">
                        <div className="section-content-container">
                            <div className="section-vertical-centered-layout">
                                <Loader type="bubble-ping" color="#071F5A" bgColor="#071F5A" size={200} />
                                <h2>Traitement...</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

    return (
        <section className="section">
            <div className="section-container">
                <div className="section-container-layout">
                    <div className="section-content-container">
                        <div className="section-vertical-centered-layout">
                            <h2>{message}</h2>
                            <Link to={link}>
                                <button className="dark-bg">{buttonText}</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}