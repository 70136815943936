import dayjs from "dayjs"
import { useState } from 'react'
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useUser } from "../../../utils/contexts/userContext";
import { useNavigate } from "react-router-dom";

export default function LicensekeyDetails({ licenseKey }) {

    const { user } = useUser()
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const [copied, setCopied] = useState(false)

    

    function copyToClipboard() {
        navigator.clipboard.writeText(licenseKey.key)
        setCopied(true)
    }

    return (
        <>
            <h2 className="section-subtitle-special">Détails de la clé de license</h2>
            <div className="section-info-container">
                <ul className="section-account-subscription-infos-list">
                    <li className="section-account-subscription-info">
                        <strong>Clé de license</strong>
                        <span className="license-key-container" onClick={() => copyToClipboard()}>{licenseKey.key}</span>
                        <span className="license-key-copy-container">{copied ? "Copié" : "Cliquez pour copier"}</span>
                    </li>
                    <li className="section-account-subscription-info">
                        <strong>Date de début</strong> {dayjs(licenseKey.start_date).format("DD-MM-YYYY")}
                    </li>
                    <li className="section-account-subscription-info">
                        <strong>Date de fin</strong> {dayjs(licenseKey.end_date).format("DD-MM-YYYY")}
                    </li>
                    <li className="section-account-subscription-info">
                        <strong>Partition</strong> {licenseKey.partition_name}
                    </li>
                    <li className="section-account-subscription-info">
                        <strong>Numéro de modèle</strong> {licenseKey.model_number}
                    </li>
                    <li className="section-account-subscription-info">
                        <strong>Numéro de série</strong> {licenseKey.serie_number}
                    </li>
                    <li className="section-account-subscription-info">
                        <strong>Version de l'OS</strong> {licenseKey.os_version}
                    </li>
                    <li className="section-account-subscription-info">
                        <strong>Nombre d'utilisateurs</strong> {licenseKey.max_user_count}
                    </li>
                </ul>
            </div>
            <button
                className={"dark-bg " + (isLoading ? "loading-button" : undefined)}
                disabled={isLoading}
                onClick={() => navigate('/contact')}>Contactez nous pour plus d'informations</button>
        </>
    )
}