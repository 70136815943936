const api_url = process.env.NODE_ENV === "production" ? "https://dips400.com" : "http://localhost:8080"

export async function createCustomer(user, shippingData, logoutUser) {
    try {
        const response = await fetch(`${api_url}/api/payement/create-customer`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + user.token
                },
                body: JSON.stringify({user : user, shippingData: shippingData}),
            })

        switch (response.status) {
            case 200:
                return await response.json();
            case 404:
                return null;
            case 401:
                logoutUser()
                return window.location = '/login';
            case 500:
                logoutUser()
                window.location = '/login'
                return [];
            default:
                const error = await response.json();
                throw error;
        }

    } catch (error) {
        console.error(error.message)
    }
}

export async function updateCustomer(user, customerData, logoutUser) {
    try {
        const response = await fetch(`${api_url}/api/payement/update-customer`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + user.token
                },
                body: JSON.stringify({user : user, customerData: customerData}),
            })

        switch (response.status) {
            case 200:
                return await response.json();
            case 404:
                return null;
            case 401:
                logoutUser()
                return window.location = '/login';
            case 500:
                logoutUser()
                window.location = '/login'
                return [];
            default:
                const error = await response.json();
                throw error;
        }

    } catch (error) {
        console.error(error.message)
    }
}

export async function createSubscription(user, priceId, customerId, quantity, logoutUser) {
    try {
        const response = await fetch(`${api_url}/api/payement/create-subscription`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + user.token
                },
                body: JSON.stringify({
                    user: user,
                    priceId: priceId,
                    customerId: customerId,
                    quantity : quantity
                }),
            })

        switch (response.status) {
            case 200:
                return await response.json();
            case 404:
                return null;
            case 401:
                logoutUser()
                return window.location = '/login';
            case 500:
                logoutUser()
                window.location = '/login'
                return [];
            default:
                const error = await response.json();
                throw error;
        }

    } catch (error) {
        console.error(error.message)
    }
}

export async function updateSubscription(user, subscription, pricingPlan, quantity, logoutUser) {
    try {
        const response = await fetch(`${api_url}/api/payement/update-subscription`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + user.token
                },
                body: JSON.stringify({
                    user: user,
                    subscription: subscription,
                    pricingPlan: pricingPlan,
                    quantity : quantity
                }),
            })

        switch (response.status) {
            case 200:
                return await response.json();
            case 401:
                logoutUser()
                return window.location = '/login';
            case 500:
                logoutUser()
                window.location = '/login'
                return [];
            default:
                return null;
        }

    } catch (error) {
        console.error(error.message)
    }
}

export async function deleteSubscription(user, licenseKey, logoutUser) {
    try {
        const response = await fetch(`${api_url}/api/payement/delete-subscription`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + user.token
                },
                body: JSON.stringify({
                    licenseKey: licenseKey,
                    user: user
                }),
            })

        switch (response.status) {
            case 200:
                return await response.json();
            case 401:
                logoutUser()
                return window.location = '/login';
            case 500:
                logoutUser()
                window.location = '/login'
                return [];
            default:
                return null;
        }

    } catch (error) {
        console.error(error.message)
    }
}

export async function getBillingInformations(customerId, subscriptionId, logoutUser) {
    try {
        const response = await fetch(`${api_url}/api/payement/retreive-billing-infos/${customerId}/${subscriptionId}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"                },
            })

        switch (response.status) {
            case 200:
                return await response.json();
            case 404:
                return null;
            case 401:
                logoutUser()
                return window.location = '/login';
            case 500:
                logoutUser()
                window.location = '/login'
                return [];
            default:
                const error = await response.json();
                throw error;
        }

    } catch (error) {
        console.error(error.message)
    }
}